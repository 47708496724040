import axios from 'utils/axios'
// import Qs from 'qs'
// 获取当前门店的可用额度   /blade-credit-fd/credit/memberStore/getCurrentMemberStoreAvailableQuota
export const getCurrentMemberStoreAvailableQuota = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/memberStore/getCurrentMemberStoreAvailableQuota';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                //    params:{memberStoreAddress:memberStoreAddress},
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 获取当前门店的授信基本信息  /blade-credit-fd/credit/memberStore/getCurrentMemberStoreCreditBaseInfo

export const getCurrentMemberStoreCreditBaseInfo = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/memberStore/getCurrentMemberStoreCreditBaseInfo';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                //    params:{memberStoreAddress:memberStoreAddress},
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 获取当前门店的授信状态   /blade-credit-fd/credit/memberStore/getCurrentMemberStoreCreditStatus

export const getCurrentMemberStoreCreditStatus = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/memberStore/getCurrentMemberStoreCreditStatus';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                //    params:{memberStoreAddress:memberStoreAddress},
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

//查询结算单列表   /blade-credit-fd/credit/settlementOrder/listCurrentMemberStoreSettlementOrder
export const listCurrentMemberStoreSettlementOrder = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/settlementOrder/listCurrentMemberStoreSettlementOrder';
        axios.get(url, {
            //请求头配置  
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            //参数列表
               params:datas,
        })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 获取结算单日期  /blade-credit-fd/credit/memberStore/getSettlementOrderDate
export const getSettlementOrderDate = () => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/memberStore/getSettlementOrderDate';
        axios.get(url, {
                //请求头配置  
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                //参数列表
                //    params:{memberStoreAddress:memberStoreAddress},
            })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 获取当前门店的回款记录 
export const getCurrentMemberStoreOfflineRepay = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/offlineRepayRecord/getCurrentMemberStoreOfflineRepay';
        axios.get(url, {
            //请求头配置  
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            //参数列表
               params:datas,
        })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}



// 查询结算单订单明细列表   /blade-credit-fd/credit/memberStore/getSettlementOrderDetail
export const getSettlementOrderDetail = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/memberStore/getSettlementOrderDetail';
        axios.get(url, {
            //请求头配置  
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            //参数列表
               params:{id:datas},
        })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 查询结算单还款列表 /blade-credit-fd/credit/memberStore/getSettlementOrderRepay
export const getSettlementOrderRepay = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/memberStore/getSettlementOrderRepay';
        axios.get(url, {
            //请求头配置  
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            //参数列表
               params:{id:datas},
        })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// 发起支付  /blade-credit-fd/credit/pay/initiatePayment  post

export const initiatePayment=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-credit-fd/credit/pay/initiatePayment';
        axios({
            headers: {
                'Content-Type': 'application/json',
            },
            url:url,
            method:"post",
            data:datas
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 判断是否还款成功    /blade-credit-fd/credit/pay/checkPCPayIsSuccess

export const checkPCPayIsSuccess = (datas) => {
    return new Promise((resolve, reject) => {
        let url = '/api/blade-credit-fd/credit/pay/checkPCPayIsSuccess';
        axios.get(url, {
            //请求头配置  
            headers: {
                'Content-Type': 'application/json'
            },
            //参数列表
               params:{id:datas},
        })
            .then((data) => {
                var result = data;
                return resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
    })
}